<template>
  <div>
    <p class="font" @click="goToEmailAddress" :style="{color: color}">{{email}}</p>
  </div>
</template>

<script>
import { confirmEmailLogin } from "~/baseUtils";
export default {
  props: {
    email: {
      type: String
    },
    color: {
      type: String,
      default: '#0083f6'
    }
  },
  methods: {
    goToEmailAddress() {
      let emailAddress = confirmEmailLogin(this.email);
      if (emailAddress) {
        window.location.href = emailAddress;
      } else {
        return;
      }
    }
  }
};
</script>

<style scoped lang="less">
.font {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
</style>